import React, { Fragment } from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import PostEntry from "../components/PostEntry"
import BlogsMobileBanner from "../components/BlogsMobileBanner"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./blog.module.scss"

const PreviousLink = ({ pageNumber }) => {
  let previousLink = null

  if (!pageNumber) {
    return null
  } else if (1 === pageNumber) {
    previousLink = "/blogs/"
  } else if (1 < pageNumber) {
    previousLink = `/blogs/page/${pageNumber - 1}`
  }

  return (
    <button onClick={() => navigate(previousLink)} className={styles.paginate}>
      {`« Previous`}
    </button>
  )
}

const NextLink = ({ hasNextPage, pageNumber }) => {
  if (hasNextPage) {
    return (
      <button
        onClick={() => navigate(`/blogs/page/${pageNumber + 1}`)}
        className={styles.paginate}
      >
        {`Next »`}
      </button>
    )
  } else {
    return null
  }
}

// react-responsive (easy mode - ssr)
const MaxWidth575 = ({ children }) => {
  const isMax575px = useMediaQuery({ maxWidth: 575 })
  return isMax575px ? children : null
}

const MinWidth576 = ({ children }) => {
  const isMin576px = useMediaQuery({ minWidth: 576 })
  return isMin576px ? children : null
}

const BlogTemplate = props => {
  const {
    data,
    pageContext: { hasNextPage, pageNumber },
  } = props

  const blogPageNumber = pageNumber ? ` Page ${pageNumber + 1}` : ``

  return (
    <Layout>
      <SEO
        title={`Blog ${blogPageNumber}`}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.bannerBackgroundImage.publicURL});
          }
        `}
      >
        <MaxWidth575>
          <BlogsMobileBanner
            title="Blogs"
            subTitle="Take a break and read all about it."
          />
        </MaxWidth575>
        <MinWidth576>
          <Container className={styles.bannerContainer}>
            <Row className={styles.bannerRow}>
              <Col md={5} className={styles.header}>
                <h1>Blogs</h1>
                <p>Take a break and read all about it.</p>
              </Col>
              <Col className={styles.imgCol}>
                <Img
                  fluid={data.banner.childImageSharp.fluid}
                  className={styles.bannerImg}
                  alt="AccountablePH Blog"
                />
              </Col>
            </Row>
          </Container>
        </MinWidth576>
      </Container>
      <Container fluid className="pb-5 pt-5">
        <Row>
          <Col className="d-flex justify-content-center">
            <div
              className="card-columns"
              style={{
                maxWidth: "1040px",
                columnGap: "1.5rem",
              }}
            >
              {data &&
                data.wpcontent &&
                data.wpcontent.posts.nodes.map(post => (
                  <Fragment key={post.id}>
                    <PostEntry post={post} />
                  </Fragment>
                ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pb-5">
        <div className={styles.paginatorContainer}>
          <div className={styles.prevLink}>
            <PreviousLink pageNumber={pageNumber} />
          </div>
          <div className={styles.nextLink}>
            <NextLink hasNextPage={hasNextPage} pageNumber={pageNumber} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query GET_POSTS_AND_GATSBY_IMAGES($ids: [ID]) {
    wpcontent {
      posts(where: { in: $ids }) {
        nodes {
          ...PostEntryFragment
        }
      }
    }

    seoBanner: file(relativePath: { eq: "banner/seo-blogs.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerBackgroundImage: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    banner: file(relativePath: { eq: "blog/blogs.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
